/* global jwplayer */

/**
 * @namespace components
 */

import React from 'react';
import PropTypes from 'prop-types';
import { isClient, miniGazelle, getUrl, dataLayer, currentLocale } from '../../../helpers';
import request from '../../../http/request';

/**
 * @class JwVideo
 *
 * Custom router/link component to manage rendering of internal pro.sony links and external links.
 *
 * @memberof components
 *
 *
 * @param {string} vimeoId
 * @param {string} mobile
 * @param {string} sd
 * @param {string} hls
 * @param {string} poster
 * @param {object} parentProps - Parent module property object passed directly to the DataLayer
 *
 * @todo DW [2019/12/19]: Study replacing getElementById with `ref`
 * @todo DW [2019/12/20]: Write tests, factoring in requirement to load remote script
 *
 * @example
 * return (
 *   JwVideo {...videoData} />
 * )
 */

class JwVideo extends React.Component {
    constructor() {
        super();
        this.state = {
            videoId: Math.random()
                .toString(36)
                .slice(2),
        };

        this.customEventHandler = this.customEventHandler.bind(this);
    }

    /**
     * CustomEvent handler that fires the dataLayer tracking event with configurable names and props
     *
     * @param {string} eventName - eventName to be passed to the dataLayer directly
     * @param {object} props - props passed as the detail payload of the dataLayer event
     *
     * @example
     * this.customEventHandler('video_started', {
     *  ...props,
     *  videoProps
     * });
     */

    customEventHandler(eventName = '', props = {}) {
        const { parentProps = {} } = props;
        const { data = {}, name } = parentProps;
        const { logicalName = '' } = data;

        dataLayer.triggerEvent(eventName, {
            id: logicalName,
            name,
            ...props,
        });
    }

    componentDidMount() {
        const { vimeoId, mobile, sd, hls, poster } = this.props;
        const { videoId } = this.state;

        const props = this.props;

        let videoProps = {
            ...props,
        };

        videoProps.parentProps = null;

        if (isClient()) {
            let trackedHalfwayCallback = false;

            const jwplayerInit = texttracks => {
                try {
                    jwplayer('jwplayer_' + videoId)
                        .setup({
                            width: '100%',
                            aspectratio: '16:9',
                            playlist: [
                                {
                                    image: poster,
                                    sources: [
                                        {
                                            file: sd,
                                            label: 'Standard',
                                        },
                                        {
                                            file: mobile,
                                            label: 'Mobile',
                                        },
                                        {
                                            file: hls,
                                            label: 'HLS',
                                        },
                                    ],
                                    tracks: texttracks ? texttracks : null,
                                },
                            ],
                        })
                        .on('play', props => {
                            console.log('Playing!');
                            this.customEventHandler('video_started', {
                                ...props,
                                videoProps,
                            });
                        })
                        .on('pause', props => {
                            this.customEventHandler('video_paused', {
                                ...props,
                                videoProps,
                            });
                        })
                        .on('complete', props => {
                            this.customEventHandler('video_completed', {
                                ...props,
                                videoProps,
                            });
                        })
                        .on('seek', props => {
                            this.customEventHandler('video_seek', {
                                ...props,
                                videoProps,
                            });
                        })
                        .on('time', ({ duration, position }) => {
                            const percentage = (position * 100) / duration;

                            if (Math.floor(percentage) >= 50 && !trackedHalfwayCallback) {
                                trackedHalfwayCallback = true;

                                this.customEventHandler('video_over_half_watched', {
                                    duration,
                                    position,
                                    videoProps,
                                });
                            }
                        });
                } catch (err) {
                    console.warn('jwplayer is missing, skipping video init');
                    console.warn(err);
                }
            };

            // collected fetchTexttracks logic
            const fetchTexttracks = async () => {
                let fetchUrl;
                const basePath = getUrl();
                const locale = currentLocale.get();
                basePath === 'http://localhost:3001'
                    ? // need localApi copy to avoid localhost CORS issue
                      (fetchUrl = 'http://localhost:8080/texttracks')
                    : // will always have a vimeoId
                      (fetchUrl = `${basePath}/${locale}/api/meerkat/vimeo/${vimeoId}/texttracks`);

                try {
                    const response = await request.get(fetchUrl);

                    /* parse the body */
                    const { body = {} } = response;
                    const { data = [], total } = body[0];

                    const tracks = [];
                    // extract tracks
                    if (total > 0) {
                        const localeLanguage = currentLocale.get().split('_')[0];
                        data.map(texttrack => {
                            return tracks.push({
                                file: texttrack.link,
                                label: miniGazelle.label(`vimeo_${texttrack.language}`, true),
                                kind: 'captions',
                                // switch on default subtitles by locale
                                default: localeLanguage === texttrack.language ? true : false,
                            });
                        });

                        // if no default, switch on English subtitles
                        if (!tracks.some(item => item.default === true)) {
                            tracks.forEach((entry, index, theArray) => {
                                // if tracks has English
                                if (entry.label === 'English') {
                                    theArray[index].default = true;
                                }
                            });
                        }
                    }

                    jwplayerInit(tracks);
                } catch (err) {
                    if (err) {
                        console.warn(err);
                        // if error initialise video with no texttracks
                        jwplayerInit();
                    }
                }
            };
            fetchTexttracks();
        }
    }

    render() {
        const { videoId } = this.state;
        const jwContainerMarkup = `<div className="text-center" id="jwplayer_${videoId}">Loading video...</div>`;

        return (
            <div className="jw-video-common">
                <div className="jw-vid-abs" dangerouslySetInnerHTML={{ __html: jwContainerMarkup }} />
            </div>
        );
    }
}

JwVideo.propTypes = {
    vimeoId: PropTypes.string,
    mobile: PropTypes.string,
    sd: PropTypes.string,
    hls: PropTypes.string,
    poster: PropTypes.string,
    parentProps: PropTypes.object,
};

export default JwVideo;
