import React from 'react';

function getMobileLayout({ dynamicClasses, titleMarkup, contentMarkup, composedMedia, title, content }) {
    return (
        <div className={dynamicClasses}>
            <div className="col-md-10 col-md-offset-1 text-center text-wrapper">
                {(title || content) && (
                    <div>
                        {titleMarkup}
                        {contentMarkup}
                    </div>
                )}
            </div>
            <div className="col-md-12 image-wrapper" data-testid="mobile-image">
                {composedMedia}
            </div>
        </div>
    );
}

function getTextRightOrLeft({ dynamicClasses, titleMarkup, contentMarkup, composedMedia, alignment }) {
    return (
        <div className={dynamicClasses}>
            <div className="row">
                {alignment === 'text-left-image-right' && (
                    <div className="col-md-6">
                        {titleMarkup}
                        {contentMarkup}
                    </div>
                )}
                <div className="col-md-6">{composedMedia}</div>
                {alignment === 'text-right-image-left' && (
                    <div className="col-md-6">
                        {titleMarkup}
                        {contentMarkup}
                    </div>
                )}
            </div>
        </div>
    );
}

function getTextTopOrBottom({
    dynamicClasses,
    titleMarkup,
    contentMarkup,
    composedMedia,
    title,
    content,
    alignment,
}) {
    return (
        <div className={dynamicClasses}>
            {(title || content) && alignment === 'text-top-image-bottom' && (
                <div className="col-md-10 offset-md-1 text-center text-wrapper">
                    {titleMarkup}
                    {contentMarkup}
                </div>
            )}
            <div className="col-md-12 image-wrapper">
                {composedMedia}
            </div>
            {(title || content) && alignment === 'text-bottom-image-top' && (
                <div className="col-md-10 offset-md-1 text-center text-wrapper">
                    {titleMarkup}
                    {contentMarkup}
                </div>
            )}
        </div>
    );
}

function getDefaultContent({ dynamicClasses, titleMarkup, contentMarkup, composedMedia, title, content }) {
    return (
        <div className={dynamicClasses}>
            {(title || content) && (
                <div className="col-md-6 text-wrapper">
                    {titleMarkup}
                    {contentMarkup}
                </div>
            )}
            <div className="col-md-6 image-wrapper">
                {composedMedia}
            </div>
        </div>
    );
}

function getComposedContent({
    isMobile,
    alignment,
    titleMarkup,
    contentMarkup,
    composedMedia,
    dynamicClasses,
    title,
    content,
}) {
    if (isMobile) {
        return getMobileLayout({
            dynamicClasses,
            titleMarkup,
            contentMarkup,
            composedMedia,
            title,
            content,
        });
    }

    switch (alignment) {
        case 'text-right-image-left':
        case 'text-left-image-right':
            return getTextRightOrLeft({ dynamicClasses, titleMarkup, contentMarkup, composedMedia, alignment });
        case 'text-top-image-bottom':
        case 'text-bottom-image-top':
            return getTextTopOrBottom({
                dynamicClasses,
                titleMarkup,
                contentMarkup,
                composedMedia,
                title,
                content,
                alignment,
            });
        default:
            return getDefaultContent({
                dynamicClasses,
                titleMarkup,
                contentMarkup,
                composedMedia,
                title,
                content,
            });
    }
}

export default getComposedContent;
