/**
 * @namespace components
 */

import React from 'react';
import PropTypes from 'prop-types';

import JwVideo from '../JwVideo/JwVideo';

/**
 * @class MediaEmbed
 *
 * @description Generic media component that renders as a video, image, youtube
 *
 * @memberof components
 *
 *
 * @param {string=} mediaType - One of video, image, youtube
 * @param {object=} mediaSources - Video object
 * @param {string=} mediaSources.sd - SD video channel
 * @param {string=} mediaSources.hd - HD video channel
 * @param {string=} mediaSources.hls - Apple HLS video channel
 * @param {string=} mediaPoster - Poster image for video
 * @param {string=} mediaSrc - src attribute for image type
 * @param {string=} altText - Optional alt text for images, title text for youtube embeds
 * @param {string=} imageClassNames - Optional classNames passed to the image type
 * @param {string=} vimeoId - vimeoId number extracted from vimeo instance
 * @param {string=} youtubeSourceUrl - YouTube iframe src attribute
 * @param {object=} parentProps - Any props object that will be passed to the video component
 *
 * @example
 *
 * return <MediaEmbed {...props} />
 */

const MediaEmbed = (props = {}) => {
    const {
        mediaType,
        mediaSources,
        mediaPoster,
        parentProps = {},
        mediaSrc,
        altText,
        imageClassNames,
        vimeoId,
        youtubeSourceUrl,
        vimeoSourceUrl,
    } = props;

    let composedMedia;

    switch (mediaType) {
        case 'youtube': {
            composedMedia = (
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                        className="embed-responsive-item"
                        src={youtubeSourceUrl}
                        frameBorder="0"
                        data-allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={altText}
                    />
                </div>
            );
            break;
        }
        case 'video': {
            const { mobile, sd, hls } = mediaSources;

            let videoData = {
                mobile,
                sd,
                hls,
                vimeoId: vimeoId,
                poster: mediaPoster,
            };

            composedMedia = <JwVideo {...videoData} parentProps={parentProps} />;
            break;
        }
        case 'vimeo': {
            composedMedia = (
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                        className="embed-responsive-item"
                        src={vimeoSourceUrl}
                        frameBorder="0"
                        data-allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            );
            break;
        }
        default: {
            composedMedia = <img src={mediaSrc} alt={altText} className={imageClassNames} />;
        }
    }

    return composedMedia;
};

MediaEmbed.propTypes = {
    mediaType: PropTypes.oneOf(['image', 'video', 'youtube']),
    mediaSources: PropTypes.shape({
        mobile: PropTypes.string,
        sd: PropTypes.string,
        hls: PropTypes.string,
    }),
    mediaPoster: PropTypes.string,
    parentProps: PropTypes.any,
    mediaSrc: PropTypes.string,
    altText: PropTypes.string,
    imageClassNames: PropTypes.string,
    vimeoId: PropTypes.string,
    youtubeSourceUrl: PropTypes.string,
    vimeoSourceUrl: PropTypes.string,
};

export default MediaEmbed;
